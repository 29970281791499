import React, {FC, useEffect, useRef, useState} from "react";
import {Button, Divider, Dropdown, MenuProps, Space, theme, Tooltip} from "antd";
import {BookOutlined, DownOutlined, SaveOutlined} from "@ant-design/icons";
import {QlikEmbed, QlikEmbedConfig} from "@qlik/embed-react";
import {AdminTitle, useAdminPanelContext} from "../AdminPanelContext";
import {useConnection} from '@sezenta/connection';
import {useAuth0} from "@auth0/auth0-react";
import {useSearchParams} from "react-router-dom";
import * as querystring from "querystring";
import {useClientContext} from "../../context/ClientContext";
import {useEnvironmentContext} from "../../context/EnvironmentContext";
import {FormSchema} from "@sezenta/antd-schema-form";
import {DataEditModal, DataEditModalInstance} from "../../components/DataEditModal";
import {useEvents} from "../../common/EventContext";

const {useToken} = theme;

type QlikProps = {
  hostConfig: any,
  appId: string,
  sheetId: string,
}

const QlikEmbedUI: FC<QlikProps> = (props) => {
  const {hostConfig, sheetId, appId} = props

  useEffect(() => {
    console.log('Component mounted');
    return () => {
      console.log('Component unmounted');
    };
  }, [appId]);
  // @ts-ignore
  return (
    <QlikEmbedConfig.Provider value={hostConfig as any}>
      {/*<div>*/}
      {/*    /!*<div className="selections-bar">*!/*/}
      {/*    /!*    <QlikEmbed ui="analytics/selections" appId={appId} />*!/*/}
      {/*    /!*</div>*!/*/}
      {/*    <div>*/}
      {/*         /!*@ts-ignore*!/*/}
      {/*        <QlikEmbed ui="classic/app" app={appId} sheet={sheetId} />*/}
      {/*    </div>*/}
      {/*</div>*/}
      <div className="grow shrink"> {/* Set a specific height to the containing div */}
        <div> {/* Apply defined styles for QlikEmbed */}
          {/*@ts-ignore*/}
          <QlikEmbed ui="classic/app" app={appId} sheet={sheetId}/>
        </div>
      </div>
    </QlikEmbedConfig.Provider>
  )
}

const Dashboard: FC = () => {
  const [appId, setAppId] = useState<string>();
  const [sheetId, setSheetId] = useState<string>();
  const [sheetName, setSheetName] = useState<string>('');
  const [menu, setMenu] = useState<any[]>([]);
  const [bookmarkMenu, setBookmarkMenu] = useState<any[]>([]);
  const [bookmarks, setBookmarks] = useState<any[]>([]);
  const [mount, setMount] = useState<boolean>(true);
  const [selectedBookmark, setSelectedBookmark] = useState<any>(undefined);

  let [searchParams, setSearchParams] = useSearchParams();
  const {client} = useClientContext()
  const connection = useConnection();
  const events = useEvents();
  const {config} = useEnvironmentContext();
  const {user, isLoading, isAuthenticated, getAccessTokenSilently, loginWithRedirect} = useAuth0();
  const bookmarkCreateModal = useRef<DataEditModalInstance>(null);

  const {token} = useToken();

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle: React.CSSProperties = {
    boxShadow: 'none',
  };

  const bookmarkSchema: FormSchema = [
    {
      id: 'bookmarkName',
      type: 'string',
      name: 'Bookmark Name',
      options: {
        rules: [
          {required: true, message: 'Bookmark Name is required'},
        ],
      },
    },
    {
      id: 'bookmarkDescription',
      type: 'string',
      name: 'Bookmark Description',
      options: {
        rules: [
          {required: true, message: 'Bookmark Description is required'},
        ],
      },
    }
  ];

  useEffect(() => {
    if (!mount) {
      const timer = setTimeout(() => {
        setMount(true);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [mount]);

  const onChange = (newSheetId: string, newAppId: string, currentId: string, changeBookmark?: boolean) => {
    if (changeBookmark) {
      setSelectedBookmark(undefined);
    }
    setMount(false);
    setAppId(newAppId);
    setSheetId(newSheetId);
    setSearchParams(querystring.stringify({id: currentId}));
  };

  const hostConfig = {
    host: config.qlikTenantUri,
    clientId: config.qlikClientId,
    redirectUri: config.qlikRedirectUri,
    accessTokenStorage: "session",
    authType: "oauth2",
    autoRedirect: true
  };

  const previousClientId = useRef<string>('new');

  useEffect(() => {
    if (!user) {
      return;
    }
    if (client?.id === '') {
      return;
    }
    const currentId = searchParams.get('id');
    const reload = client.id !== previousClientId.current || !currentId;
    connection.get(`menus/${client.id}/menu`)
      .then(value => {
        const items = value.data.items;
        let defaultSheet: any;
        const createMenuFromItem = (item: any) => {
          const val: any = {
            label: item.name,
            key: item.id,
          };
          if (item.appId && item.sheetId && (item.items ?? []).length === 0) {
            if (defaultSheet === undefined) {
              defaultSheet = item;
            }
            val.onClick = () => {
              setSheetName(item.name);
              onChange(item.sheetId, item.appId, item.id, true);
            };
          }
          if ((item.items ?? []).length > 0) {
            val.children = item.items.map((vi: any) => createMenuFromItem(vi))
          }
          if (currentId && currentId === `${item.id}` && !reload) {
            setSheetName(item.name);
            onChange(item.sheetId, item.appId, item.id, true);
          }
          return val;
        }
        console.log('CC', items.map(createMenuFromItem));
        setMenu(items.map(createMenuFromItem));
        if (reload) {
          setSheetName(defaultSheet.name);
          onChange(defaultSheet.sheetId, defaultSheet.appId, defaultSheet.id, true);
        }
      }).catch((error) => {
      console.log('ERRRRR', error);
    })
  }, [client]);

  useEffect(() => {
    const getAllBookmark = () => {
      connection.post('qlik/bookmark/get-all', {appId}).then((res) => {
        setBookmarks(res.data);
        const bookmarkMenuArray: any[] = [];
        res.data?.map((val: any) => {
          bookmarkMenuArray.push({
            label: val.bookmarkName,
            key: val.id,
          })
        })
        setBookmarkMenu(bookmarkMenuArray);
      }).catch((e) => console.log(e));
    }
    if (appId) {
      getAllBookmark();
    }
    events.on('BOOKMARK', getAllBookmark);
    return () => {
      events.off('BOOKMARK', getAllBookmark);
    };
  }, [appId, connection, events]);

  const onBookmarkClick: MenuProps['onClick'] = ({key}) => {
    const bookmark = bookmarks.filter((val) => val.id === key)[0];
    setSelectedBookmark(bookmark)
    if (bookmark.sheetId !== sheetId) {
      onChange(bookmark.sheetId, bookmark.appId, bookmark.menuId, false);
    }
    connection.post('qlik/bookmark/apply', {appId, bookmarkId: key}).then().catch((e) => console.log(e));
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <AdminTitle>
        <div className={'flex flex-row justify-between align-baseline'}>
          <div>
            {menu.map(m => ((m.children?.length ?? 0) > 0 ?
                <Dropdown key={m.key} className={'mr-2 bg-primary text-white'} menu={{items: m.children}}
                          trigger={['click']}>
                  <Button type="text" onClick={(e) => e.preventDefault()}>
                    <Space>
                      {m.label}
                      <DownOutlined/>
                    </Space>
                  </Button>
                </Dropdown> : <Button type="text" onClick={(e) => m.onClick()}>{m.label}</Button>
            ))}
          </div>
          <div className={'flex flex-row gap-2 items-center'}>
            <div className="text-base font-medium">
              {sheetName ?? ''}
              <div className="text-xs text-center">
                {selectedBookmark?.bookmarkName ?? ''}
              </div>
            </div>
            {appId && (
              <>
                <Tooltip title={'Apply Reports'}>
                  <Dropdown className={'mx-2'} menu={{items: bookmarkMenu, onClick: onBookmarkClick}}
                            trigger={['click']}>
                    <Button icon={<BookOutlined/>}/>
                  </Dropdown>
                </Tooltip>

                <Tooltip title={'Save Reports'}>
                  <Dropdown className={'mx-2'} menu={{items: []}}
                            trigger={['click']}
                            dropdownRender={(menu) => (
                              <div style={contentStyle}>
                                {React.cloneElement(menu as React.ReactElement, {style: menuStyle})}
                                <Divider style={{margin: 0}}/>
                                <Space style={{padding: 8}}>
                                  <Tooltip title={'Apply Report to Update'}>
                                    <Button type="primary"
                                            disabled={!selectedBookmark}
                                            onClick={() => bookmarkCreateModal.current?.edit(selectedBookmark, bookmarkSchema, `Update Report`, `qlik/bookmark/update`, record => {
                                              const mId = searchParams.get('id') ?? '-1';
                                              record.appId = appId;
                                              record.sheetId = sheetId;
                                              record.menuId = parseInt(mId);
                                              return record;
                                            })}>
                                      Update Report</Button>
                                  </Tooltip>
                                </Space>
                                <Divider style={{margin: 0}}/>
                                <Space style={{padding: 8}}>
                                  <Button type="primary"
                                          onClick={() => bookmarkCreateModal.current?.create(bookmarkSchema, `Create Report`, `qlik/bookmark/create`, record => {
                                            const mId = searchParams.get('id') ?? '-1';
                                            record.appId = appId;
                                            record.sheetId = sheetId;
                                            record.menuId = parseInt(mId);
                                            return record;
                                          })}>
                                    Create Report</Button>
                                </Space>
                              </div>
                            )}
                  >
                    <Button icon={<SaveOutlined/>}/>
                  </Dropdown>
                </Tooltip>
              </>
            )}
          </div>
        </div>

        {/*{createPortal(*/}
        {/*  <div>*/}
        {/*    {appId && (*/}
        {/*      <Tooltip title={'Bookmarks'}>*/}
        {/*        <Dropdown className={'mx-2'} menu={{items: bookmarkMenu, onClick: onBookmarkClick}}*/}
        {/*                  trigger={['click']}*/}
        {/*                  dropdownRender={(menu) => (*/}
        {/*                    <div style={contentStyle}>*/}
        {/*                      {React.cloneElement(menu as React.ReactElement, {style: menuStyle})}*/}
        {/*                      <Divider style={{margin: 0}}/>*/}
        {/*                      <Space style={{padding: 8}}>*/}
        {/*                        <Button type="primary"*/}
        {/*                                onClick={() => bookmarkCreateModal.current?.create(bookmarkSchema, `Create Bookmark`, `qlik/bookmark/create`, record => {*/}
        {/*                                  const mId = searchParams.get('id') ?? '-1';*/}
        {/*                                  record.appId = appId;*/}
        {/*                                  record.sheetId = sheetId;*/}
        {/*                                  record.menuId = parseInt(mId);*/}
        {/*                                  return record;*/}
        {/*                                })}>*/}
        {/*                          Create Bookmark</Button>*/}
        {/*                      </Space>*/}
        {/*                    </div>*/}
        {/*                  )}*/}
        {/*        >*/}
        {/*          <Button icon={<BookOutlined/>}/>*/}
        {/*        </Dropdown>*/}
        {/*      </Tooltip>*/}
        {/*    )}</div>, ctx.portalRef.current)}*/}
      </AdminTitle>
      <div className="relative h-screen overflow-hidden">
        {mount && sheetId && appId &&
          <QlikEmbedUI key={appId} sheetId={sheetId} appId={appId} hostConfig={hostConfig}/>}
      </div>
      <DataEditModal ref={bookmarkCreateModal} type="BOOKMARK"/>
    </div>
  )
}

export default Dashboard;
