import React, {FC, useEffect, useMemo, useState} from "react";
import {Avatar, Button, Grid, Image, Layout, Menu, MenuProps, Spin, theme,} from "antd";
import {LineChartOutlined, MenuFoldOutlined, MenuUnfoldOutlined, TeamOutlined, UserOutlined,} from "@ant-design/icons";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {useAdminPanelContext} from "./AdminPanelContext";
import {Helmet} from "react-helmet-async";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import {useConnection, useUser} from '@sezenta/connection';
import {useClientContext} from "../context/ClientContext";
import ClientSelector from "../components/ClientSelector";
import ProfilePopover from "../components/ProfilePopover";
import {Role} from "../common/roles.enum";

const {Content, Sider} = Layout;

const AdminPage: FC = () => {
  const navigation = useNavigate();
  const ctx = useAdminPanelContext();
  const connection = useConnection();
  const bp = Grid.useBreakpoint();
  const {isAuthenticated, isLoading, getAccessTokenSilently} =
    useAuth0();
  const location = useLocation();
  const {logout, user: auth0User} = useAuth0();
  const currentPath = location.pathname;
  const selectedMenuKey = currentPath.split("/")[2];
  const selectedSubMenuKey = currentPath?.split("/")[3];
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const {clients, client, changeClient} = useClientContext();
  const user = useUser();

  const {
    token: {colorBgContainer},
  } = theme.useToken();
  useEffect(() => {
    const authenticate = async () => {
      if (!isLoading && isAuthenticated && (!user || user.email !== auth0User?.email)) {
        const auth0Token = await getAccessTokenSilently();
        connection.post('auth/create-token', {token: auth0Token})
          .then((tokens: any) => connection.login(tokens.data.accessToken, tokens.data.refreshToken))
          .catch((e) => {
            console.error(e);
            navigation('/unauthorized');
          })
      }
      if (user && user?.role !== Role.Super_Admin) {
        changeClient(user?.clientId);
      }
    }
    authenticate().then();
  }, [isLoading, getAccessTokenSilently, connection, isAuthenticated, user, changeClient, auth0User]);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          onClick={() => {
            logout({
              logoutParams: {returnTo: window.location.origin},
            }).then();
          }}
        >
          Logout
        </a>
      ),
    },
  ];

  const menu = useMemo(() => {
    const menu: any[] = [];
    if (user?.role === Role.Super_Admin) {
      menu.push({
        key: "clients-menu",
        label: client?.name ?? "Halo",
        icon: (
          <div className="flex w-100 h-100 items-center justify-center">
            <ClientSelector placement="rightTop">
              {client?.image ?
                <Avatar
                  shape="square"
                  size={40}
                  style={{
                    objectFit: "cover",
                  }}
                  src={
                    client?.image ?? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPea7GIw-wIhSQCMaCNGl30hVQv7wP_JV363xEKi0yVWPMjd9xQpM5SoC95C38YwYlT-Y&usqp=CAU"
                  }
                /> :
                <Avatar
                  shape="square"
                  size={40}
                  style={{
                    objectFit: "cover",
                    backgroundColor: '#7F85F5'
                  }}
                >{client?.name?.charAt(0).toUpperCase()}</Avatar>}

            </ClientSelector>
          </div>
        ),
        style: {
          paddingInline: "0px",
          transition: "all 0.2s",
          dispaly: "flex",
          alignItem: "center",
          justifyContent: "center",
          marginBottom: "10px",
        },
      });
    }
    if (user?.role === Role.Admin || user?.role === Role.Super_Admin || user?.role === Role.User) {
      menu.push({
        key: "platform-matrics",
        icon: <LineChartOutlined/>,
        label: "Dashboard",
        className: "no-hover-label",
        onClick: () => navigation("/admin/dashboard"),
      });
    }
    if (user?.role === Role.Admin || user?.role === Role.Super_Admin) {
      menu.push({
        key: "users",
        icon: <UserOutlined/>,
        label: "Users",
        onClick: () => navigation("/admin/users"),
      });
      // menu.push({
      //   key: "tree-view",
      //   icon: <MenuUnfoldOutlined/>,
      //   label: "Tree View",
      //   onClick: () => navigation("/admin/tree-view"),
      // });
    }
    if (user?.role === Role.Super_Admin) {
      menu.push({
        key: "clients",
        icon: <TeamOutlined/>,
        label: "Clients",
        onClick: () => navigation("/admin/clients"),
      });
      menu.push({
        key: "menus",
        icon: <MenuUnfoldOutlined/>,
        label: "Menus",
        onClick: () => navigation("/admin/menus"),
      });
    }

    menu.push({
      // label: (
      //   <Button
      //     type="link"
      //     className="flex items-center w-100 p-0 text-[#A6A490]"
      //     onClick={() => {
      //       connection.logout();
      //       logout({
      //         logoutParams: {returnTo: window.location.origin},
      //       }).then();
      //     }}
      //   >
      //     <LogoutOutlined height={5}></LogoutOutlined>
      //     Sign out
      //   </Button>
      // ),
      label: `${user?.name ?? 'Halo'}`,
      key: "logout",
      className: "no-hover-tooltip",
      icon: (
        <ProfilePopover placement="rightTop">
          <div className="flex items-center justify-center">
            <Avatar
              shape="square"
              size={40}
              src={
                "https://lh3.googleusercontent.com/-RYqHPrnF5KU/AAAAAAAAAAI/AAAAAAAAAAA/ALKGfknuspjhJ742mFlWP0RoVuiDP2VBag/photo.jpg?sz=46"
              }
            />
          </div>
        </ProfilePopover>
      ),
      style: {
        position: "absolute",
        paddingInline: "0px",
        paddingLeft: "10px",
        bottom: 0,
        zIndex: 1,
        transition: "all 0.2s",
      },
    });
    // menu.push({
    //   key:'master-data',
    //   icon: <TableOutlined />,
    //   label: 'Master Data',
    //   children: masterData.map((md) => ({
    //     key: `master-data-${md.id}`,
    //     icon: <TableOutlined />,
    //     label: md.name,
    //     onClick: () => navigation(`/admin/master-data/${md.id}`),
    //   })),
    // });
    return menu;
  }, [user?.role, user?.name, client?.name, client?.image, navigation]);

  return (
    <>
      <Helmet>
        <title>Halo Analytics</title>
      </Helmet>
      <Layout className="h-screen">
        {!collapsed && !bp.md && (
          <div
            className="fixed inset-0 bg-black opacity-25 z-[9000] cursor-pointer"
            onClick={() => setCollapsed(true)}
          />
        )}
        <Sider
          width={200}
          breakpoint="lg"
          collapsedWidth={bp.md ? 65 : 0}
          collapsed={collapsed}
          trigger={null}
          style={{
            paddingTop: "15px",
            paddingInline: "8px",
          }}
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        >
          <div className={'mb-4 p-2'}>
          {
            collapsed ? (
              <Image preview={false} alt='logo' src={require('../public/logo-small.png')}/>
            ) : (
              <Image preview={false} alt='logo' src={require('../public/logo-large-dark.png')}/>
            )
          }
          </div>
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[
              selectedSubMenuKey
                ? `${selectedMenuKey}-${selectedSubMenuKey}`
                : selectedMenuKey,
            ]}
            defaultOpenKeys={[selectedSubMenuKey ? selectedMenuKey : ""]}
          >
            {menu.map((item, index) => {
              return (
                <Menu.Item
                  key={item.key}
                  icon={item.icon}
                  style={item.style}
                  onClick={item.onClick}
                  title={""}
                >
                  {item.label}
                </Menu.Item>
              );
            })}
          </Menu>
        </Sider>
        <Layout className="h-screen relative pt-[44px]">
          <div
            className="shadow flex items-center justify-center absolute left-0 top-0 right-0 z-[1000] px-4 h-[48px]"
            style={{background: colorBgContainer}}
          >
            <div className="w-full flex items-center justify-between">
              <div className="w-full flex gap-2 items-center">
                <Button
                  type="text"
                  icon={
                    collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>
                  }
                  onClick={() => setCollapsed(!collapsed)}
                  style={{
                    fontSize: "16px",
                    width: 64,
                    height: 64,
                  }}
                />
                {typeof ctx.title === "string" ? <h2 className="text-2xl font-bold">{ctx.title}</h2> :
                  <div className={'grow shrink'}>
                    {ctx.title}
                  </div>
                }
              </div>
            </div>
          </div>
          {/*<Header style={{ background: colorBgContainer }}>*/}
          {/*  <h2>{ctx.title}</h2>*/}
          {/*  <Dropdown trigger={['click']} menu={{ items }}>*/}
          {/*    <a*/}
          {/*      onClick={(e) => {*/}
          {/*        e.preventDefault();*/}
          {/*      }}>*/}
          {/*      <div className="flex items-center gap-2 h-[60px]">*/}
          {/*        <UserOutlined />*/}
          {/*        {user?.firstName}*/}
          {/*      </div>*/}
          {/*    </a>*/}
          {/*  </Dropdown>*/}
          {/*</Header>*/}
          <Content className="overflow-auto min-h-full p-4 bg-white">
            <div className="bg-white">
              <Outlet/>
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

// A component that redirects to login and preserves query parameters
const RedirectToLogin: FC<{ returnTo?: string }> = ({returnTo}) => {
  const {loginWithRedirect} = useAuth0();
  const location = useLocation();

  React.useEffect(() => {
    console.log("REEEEEEEEEEEE", location.pathname + location.search);
    const appState = {
      returnTo: returnTo || location.pathname + location.search,
    };
    console.log("ASSSSSSSSS", appState);
    loginWithRedirect({
      appState,
      authorizationParams: {
        scope: "openid profile email offline_access do:anything",
      },
    });
  }, [loginWithRedirect, returnTo, location]);

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <Spin spinning={true}/>
    </div>
  );
};

// Custom withAuthenticationRequired HOC that preserves query parameters
export function withAuthenticationRequiredCustom(Component: any, options = {}) {
  return withAuthenticationRequired(Component, {
    ...options,
    onRedirecting: () => <RedirectToLogin/>,
  });
}

// Usage
const ProtectedComponent = () => <div>Protected Content</div>;

export default withAuthenticationRequiredCustom(ProtectedComponent);

export const Admin = withAuthenticationRequiredCustom(AdminPage);
