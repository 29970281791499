'use client';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useConnection, ConnectionType } from '@sezenta/connection';
import { useEvents } from '../common/EventContext';

const searchData = (
  path: string,
  connection: ConnectionType,
  page: number,
  size: number,
  setResponse: (resp: any) => void,
  setDataSource: (data: any[]) => void,
  setLoading: (loading: boolean) => void,
  setTotal: (total: number) => void,
  search: any,
  sortColumn: string,
  sortDirection: string,
) => {
  setLoading(true);
  console.log('SEEEEEEEXXX', search);
  connection
    .get(path, {
      params: {
        page,
        limit: size,
        sortColumn,
        sortDirection,
        ...(search ?? {}),
      },
    })
    .then((value) => {
      console.log('VVVVVVVVV', value.data);
      console.log('VVVVVVVVVD', value.data.data);
      console.log('VVVVVVVVVT', value.data.total);
      setDataSource(value.data.data);
      setResponse(value.data);
      setTotal(value.data.total);
    })
    .finally(() => setLoading(false));
};
export const useDataTable = (
  path: string,
  options?: {
    eventConfig?: { name: string; updates: string[]; add: Record<string, any> };
    filter?: any;
    search?: boolean;
    defaultSort?: { column?: string; direction?: 'ASC' | 'DESC' };
  },
) => {
  const filter = options?.filter;
  const [searchEnabled, setSearchEnabled] = useState<boolean>(
    options?.search !== false,
  );
  const eventConfig = options?.eventConfig;
  const [dataSource, setDataSource] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);
  const [search, setSearch] = useState<any>(filter);
  const filterRef = useRef<any>(filter);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [response, setResponse] = useState<any>();
  const [sortColumn, setSortColumn] = useState<string>(
    options?.defaultSort?.column ?? 'id',
  );
  const [sortDirection, setSortDirection] = useState<string>(
    options?.defaultSort?.direction ?? 'ASC',
  );
  const events = useEvents();
  const connection = useConnection();
  useEffect(() => {
    if (!searchEnabled) {
      return;
    }
    console.log('SEARCH:', search, path);
    searchData(
      path,
      connection,
      1,
      10,
      setResponse,
      setDataSource,
      setLoading,
      setTotal,
      search,
      options?.defaultSort?.column ?? 'id',
      options?.defaultSort?.direction ?? 'ASC',
    );
  }, [
    connection,
    search,
    path,
    searchEnabled,
    options?.defaultSort?.column,
    options?.defaultSort?.direction,
  ]);

  // const onPaginationChange = useCallback(
  //   (page: number, pageSize: number) => {
  //     setCurrentPage(page);
  //     setPageSize(pageSize);
  //     searchData(
  //       path,
  //       connection,
  //       page,
  //       pageSize,
  //       setDataSource,
  //       setLoading,
  //       setTotal,
  //       search,
  //       sortColumn,
  //       sortDirection,
  //     );
  //   },
  //   [connection, path, search, sortColumn, sortDirection],
  // );

  useEffect(() => {
    if (!eventConfig) {
      return;
    }

    const handler = (value: any) => {
      const { action, data } = value;
      if (action === 'UPDATE') {
        const index = dataSource.findIndex(
          (v: any) =>
            eventConfig.updates.filter((x) => v[x] !== data[x]).length === 0,
        );
        if (index !== -1) {
          const newDs = [...dataSource];
          newDs[index] = data;
          setDataSource(newDs);
        }
      } else if (action === 'DELETE') {
        const index = dataSource.findIndex(
          (v: any) =>
            eventConfig.updates.filter((x) => v[x] !== data[x]).length === 0,
        );
        if (index !== -1) {
          const newDs = dataSource.filter((v: any, i: number) => i !== index);
          setDataSource(newDs);
        }
      } else {
        if (
          Object.entries(eventConfig.add).filter((x) => data[x[0]] !== x[1])
            .length === 0
        ) {
          const newDs = [data, ...dataSource];
          if (newDs.length > pageSize) {
            setDataSource(newDs.slice(0, pageSize));
          } else {
            setDataSource(newDs);
          }
        }
      }
    };
    if (eventConfig) {
      events.on(eventConfig.name, handler);
    }
    return () => {
      if (eventConfig) {
        events.off(eventConfig.name, handler);
      }
    };
  }, [events, eventConfig, dataSource, pageSize]);

  const onChange = useCallback(
    (pagination1: any, filters: any, sorter: any) => {
      console.log('ONCHANGE', pagination1, sorter);
      setCurrentPage(pagination1.page);
      setPageSize(pagination1.pageSize);
      setSortColumn(sorter?.columnKey);
      setSortDirection(sorter?.order === 'descend' ? 'DESC' : 'ASC');

      searchData(
        path,
        connection,
        pagination1.current,
        pagination1.pageSize,
        setResponse,
        setDataSource,
        setLoading,
        setTotal,
        search,
        sorter?.columnKey,
        sorter?.order === 'descend' ? 'DESC' : 'ASC',
      );
      // if (sorter) {
      //   console.log('SORTER', sorter);
      //   if (sorter.field) {
      //
      //   }
      // } else {
      //   console.log('SORTER NO', (sorter as any).field);
      //   searchData(
      //     path,
      //     connection,
      //     1,
      //     pageSize,
      //     setDataSource,
      //     setLoading,
      //     setTotal,
      //     search,
      //     sortColumn,
      //     sortDirection,
      //   );
      // }
    },
    [connection, path, search],
  );

  const updateFilter = useCallback((val: any) => {
    const newFilter = { ...(filterRef.current ?? {}), ...val };
    setSearch(newFilter);
    filterRef.current = newFilter;
  }, []);

  const setFilter = useCallback((val: any) => {
    setSearch(val);
    filterRef.current = val;
  }, []);

  return {
    filter: search,
    setFilter: setFilter,
    updateFilter,
    setSearchEnabled,
    response,
    sortColumn,
    sortDirection,
    tableProps: {
      onChange,
      dataSource,
      loading,
      pagination: {
        total,
        current: currentPage,
        pageSize,
        showTotal: (total: number, range: number[]) =>
          `Showing from ${range[0]}-${range[1]} of ${total} items`,
        // onChange: onPaginationChange,
        showSizeChanger: true,
      },
    },
  };
};
