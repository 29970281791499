import React, {FC, useCallback, useEffect, useState} from 'react';
import {AdminTitle} from "../AdminPanelContext";
import {Button, message, Tree, TreeDataNode, TreeProps} from "antd";
import {useClientContext} from "../../context/ClientContext";
import {useConnection} from "@sezenta/connection";
import {AntdTreeNodeAttribute} from "antd/es/tree/Tree";
import {DeleteOutlined, EditOutlined, FolderOpenOutlined, PlusOutlined, TableOutlined} from "@ant-design/icons";
import {MenuItemType, MenuResponseDto, MenuResponseItemDto} from "./Menu.dto";
import {MenuPageContextProvider} from "./MenuPageContext";
import MenuSheetEditor, {useMenuSheetEditor} from "./MenuSheetEditor";
import {useEventListener} from "../../common/EventContext";


const folderView = [
  {name: 'Name', value: 'name'}
];

const sheetView = [
  {name: 'Name', value: 'name'},
  {name: 'App', value: 'appName'},
  {name: 'Sheet', value: 'sheetName'},
];


////////////////////////////////////

const x = 3;
const y = 2;
const z = 1;
const defaultData: TreeDataNode[] = [];

const generateData = (_level: number, _preKey?: React.Key, _tns?: TreeDataNode[]) => {
  const preKey = _preKey || 0;
  const tns = _tns || defaultData;

  const children: React.Key[] = [];
  for (let i = 0; i < x; i++) {
    const key = `${preKey}-${i}`;
    tns.push({title: key, key});
    if (i < y) {
      children.push(key);
    }
  }
  if (_level < 0) {
    return tns;
  }
  const level = _level - 1;
  children.forEach((key, index) => {
    tns[index].children = [];
    return generateData(level, key, tns[index].children);
  });
};
generateData(z);

////////////////////////////////////

type Props = {};

const prepareTreeNodes = (items: MenuResponseItemDto[]): TreeDataNode[] => {
  const nodes: TreeDataNode[] = [];
  for (const item of items) {
    nodes.push({
      title: item.name,
      key: item.id,
      children: prepareTreeNodes(item.items ?? []),
      isLeaf: item.itemType === MenuItemType.Sheet
    });
  }
  return nodes;
}

const createItemIndex = (items: MenuResponseItemDto[], idx?: Record<number, MenuResponseItemDto>): Record<number, MenuResponseItemDto> => {
  const index = idx ?? {};
  for (const item of items) {
    index[item.id] = item;
    createItemIndex(item.items ?? [], index);
  }
  return index;
}

export const MenusPage: FC<Props> = (props) => {
  // const [gData, setGData] = useState(defaultData);
  const {client} = useClientContext();
  const [treeNodes, setTreeNodes] = useState<TreeDataNode[]>([]);
  const [menuIndex, setMenuIndex] = useState<Record<number, MenuResponseItemDto>>({});
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([0]);
  const connection = useConnection();
  const [selectedKey, setSelectedKey] = useState<number>(0);
  const [selectedItem, setSelectedItem] = useState<MenuResponseItemDto>();
  const sheetEditor = useMenuSheetEditor();
  const [menuId, setMenuId] = useState<number>(0);

  useEventListener('MENU_ITEM', useCallback((data: any) => {
    connection.get<MenuResponseDto>(`menus/by-client/${client.id}`)
      .then(m => {
        setMenuId(m.data.id);
        setMenuIndex(createItemIndex(m.data.items));
        setTreeNodes([{key: 0, title: 'Menu', children: prepareTreeNodes(m.data.items)}]);
      });
  }, [client.id, connection]));
  useEffect(() => {
    if (!client || client.id === '') {
      return;
    }
    console.log('CLIENT', client);
    connection.get<MenuResponseDto>(`menus/by-client/${client.id}`)
      .then(m => {
        setMenuId(m.data.id);
        setMenuIndex(createItemIndex(m.data.items));
        setTreeNodes([{key: 0, title: 'Menu', children: prepareTreeNodes(m.data.items)}]);
        setExpandedKeys([0]);
      });
  }, [client, connection]);

  useEffect(() => {
    console.log('CLIENT', selectedKey);
    console.log('CLIENTX', menuIndex[selectedKey]);
    if (selectedKey === 0) {
      setSelectedItem({itemType:MenuItemType.Folder, id: 0, name: 'Menu'});
    } else {
      setSelectedItem(menuIndex[selectedKey]);
    }
  }, [menuIndex, selectedKey]);

  const updateOrder = useCallback((nodes: TreeDataNode[]) => {
    const changes: {id: number, parentId: number, sortOrder: number}[] = [];
    let i = 0;
    const fillChanges = (nodes: TreeDataNode[], parentId: number) => {
      for(const node of nodes) {
        if (node.key !== 0) {
          changes.push({id: node.key as number, parentId, sortOrder: ++i});
        }

        fillChanges(node.children as TreeDataNode[], node.key as number);
      }
    }
    fillChanges(nodes, 0);
    connection.post(`menus/${menuId}/change/order`, {items: changes})
      .catch(() => message.error('Failed to save. Something went wrong!'));
  }, [connection, menuId]);

  const onDragEnter: TreeProps['onDragEnter'] = (info) => {
    console.log(info);
    // expandedKeys, set it when controlled is needed
    setExpandedKeys(info.expandedKeys)
  };

  const onDrop: TreeProps['onDrop'] = (info) => {
    console.log(info);
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]); // the drop position relative to the drop node, inside 0, top -1, bottom 1

    const loop = (
      data: TreeDataNode[],
      key: React.Key,
      callback: (node: TreeDataNode, i: number, data: TreeDataNode[]) => void,
    ) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children!, key, callback);
        }
      }
    };
    const data = [...treeNodes];

    // Find dragObject
    let dragObj: TreeDataNode;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
        item.children.unshift(dragObj);
      });
    } else {
      let ar: TreeDataNode[] = [];
      let i: number;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        // Drop on the top of the drop node
        ar.splice(i!, 0, dragObj!);
      } else {
        // Drop on the bottom of the drop node
        ar.splice(i! + 1, 0, dragObj!);
      }
    }
    setTreeNodes(data);
    updateOrder(data);
  };
  return (
    <MenuPageContextProvider>
      <div>
        <AdminTitle>
          <div className="text-xl font-bold">Menu</div>
        </AdminTitle>
        <div className="w-full flex divide-x h-[600px]">
          <div className="grow-[1] flex flex-col divide-y">
            <div className="p-4 flex justify-between items-center min-h-[64px]">
              <div className="font-bold text-lg">Menu</div>
              <div className="flex gap-2">
                <Button icon={<PlusOutlined/>} disabled={selectedItem?.itemType !== MenuItemType.Folder} onClick={() => sheetEditor.current?.open(MenuItemType.Folder, selectedItem?.id ?? 0)}>New Folder</Button>
                <Button icon={<PlusOutlined/>} disabled={selectedItem?.itemType !== MenuItemType.Folder} onClick={() => sheetEditor.current?.open(MenuItemType.Sheet, selectedItem?.id ?? 0)}>New Sheet</Button>
              </div>
            </div>
            <Tree
              icon={(nodeProps: AntdTreeNodeAttribute) => {
                if (nodeProps.isLeaf) {
                  return <TableOutlined/>
                } else {
                  return <FolderOpenOutlined/>
                }
              }}
              showIcon={true}
              className="draggable-tree"
              defaultExpandedKeys={expandedKeys}
              draggable
              blockNode
              expandedKeys={expandedKeys}
              onSelect={(selectedKeys) => {
                setSelectedKey(selectedKeys[0] as number);
              }}
              onExpand={setExpandedKeys}
              onDragEnter={onDragEnter}
              onDrop={onDrop}
              treeData={treeNodes}
              allowDrop={(options) => {
                if (options.dropNode.isLeaf && options.dropPosition === 0) {
                  return false;
                }
                return true;
              }}
            />
          </div>
          <div className="grow-[2] flex flex-col divide-y min-h-[64px]">
            <div className="p-4 flex justify-between items-center">
              <div className="font-bold text-lg">Details</div>
              <div className="flex gap-2">
                <Button  icon={<EditOutlined/>} onClick={() => sheetEditor.current?.open(selectedItem?.itemType ?? MenuItemType.Sheet, selectedItem?.id ?? 0, selectedItem)}>Edit</Button>
                <Button icon={<DeleteOutlined/>}>Delete</Button>
              </div>

            </div>
            <div className="flex flex-col gap-4 p-4">
              {(selectedItem && selectedItem.appId ? sheetView : folderView).map((item) => (
                <div className="flex flex-col gap-1" key={item.name}>
                  <div className="font-semibold">{item.name}</div>
                  <div>{(selectedItem as any)?.[item.value]}</div>
                </div>))}
            </div>
          </div>
        </div>
        <MenuSheetEditor menuId={menuId} ref={sheetEditor} />
      </div>
    </MenuPageContextProvider>
  );
};
