import React, {FC, useCallback, useEffect, useState} from "react";
import {
  Button,
  Card,
  GetProp,
  Input,
  message,
  Modal,
  Popconfirm,
  Select,
  Space,
  Table,
  Tooltip,
  Upload,
  UploadProps
} from "antd";
import { useConnection } from '@sezenta/connection';
import Form, {FormSchema} from "@sezenta/antd-schema-form";
import {useDataTable} from "../../components/DataTable";
import {dayjs} from "../../common/Util";
import {
  CheckSquareOutlined,
  CloseOutlined,
  CloseSquareOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined
} from "@ant-design/icons";
import {AdminTitle} from "../AdminPanelContext";
import {useEvents} from "../../common/EventContext";

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const fileToBase64 = async (file: File): Promise<string> => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      if (reader.result) {
        resolve(reader.result.toString());
      } else {
        reject(new Error('File to Base64 conversion failed.'));
      }
    };
    reader.onerror = () => {
      reject(new Error('File to Base64 conversion failed.'));
    };
    reader.readAsDataURL(file);
  });
};
const Clients: FC = () => {
  const events = useEvents();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [clientId, setClientId] = useState<any>();
  const [apps, setApps] = useState<any[]>([]);
  const [image, setImage] = useState<string>();

  const {tableProps, setFilter, setSearchEnabled, filter, updateFilter} = useDataTable('clients', {
    search: false,
    eventConfig: {name: 'CLIENT', add: {}, updates: ['id']},
  });
  const connection = useConnection();

  useEffect(() => {
    connection.get('qlik/apps')
      .then((res) => {
        setApps(res.data);
      })
      .catch((err) => console.log(err));
    setFilter({status: 'Active'});
    setSearchEnabled(true);
  }, [connection, setFilter, setSearchEnabled]);

  const onStatusChange = (status: string) => {
    updateFilter({...filter, status});
  }

  const onSearch = (text: string) => {
    updateFilter({...filter, searchText: text});
  }

  const onCreate = (values: any) => {
    setLoadingModal(true);
    try {
      delete values.profile;
      connection.post('clients/create', {...values, image}).then((res) => {
        events.emit('CLIENT', {action: 'CREATE', data: res.data});
        events.emit('CLIENT UPDATE');
        setLoadingModal(false);
        setVisible(false);
        form.resetFields();
        setImage(undefined);
      })
      console.log('qq', values);
    } catch (e) {
      setLoadingModal(false);
      console.log(e)
    }
  }

  const onEdit = (values: any) => {
    setLoadingModal(true);
    try {
      delete values.profile;
      connection.patch(`clients/${clientId}`, {...values, image}).then((res) => {
        events.emit('CLIENT', {action: 'UPDATE', data: res.data});
        events.emit('CLIENT UPDATE');
        setLoadingModal(false);
        setVisible(false);
        form.resetFields();
        setImage(undefined);
      })
    } catch (e) {
      setLoadingModal(false);
      console.log(e)
    }
  }

  const uploadReq = useCallback(async (file: File) => {
    try {
      const base64String = await fileToBase64(file);
      setLoading(false);
      setImage(base64String);
    } catch (e){
      console.log(e);
    }finally {
      setLoading(false);
    }
  }, [])

  const schema: FormSchema = [
    {
      id: 'name',
      type: 'string',
      name: 'Name',
      props: {placeholder: 'Client Name'},
      options: {
        rules: [
          {required: true, message: 'Client Name required'},
        ],
      },
    },
  ];

  const configSchema: FormSchema = [
    {
      id: 'baseUrl',
      type: 'string',
      name: 'Base URL',
      options: {
        rules: [
          {required: true, message: 'Base URL required'},
        ],
      },
    },
    {
      id: 'idpUri',
      type: 'string',
      name: 'Idp URI',
      options: {
        rules: [
          {required: true, message: 'Idp URI required'},
        ],
      },
    },
    {
      id: 'audience',
      type: 'string',
      name: 'Audience',
      options: {
        rules: [
          {required: true, message: 'Audience required'},
        ],
      },
    },
    {
      id: 'authClientId',
      type: 'string',
      name: 'Auth0 Client Id',
      options: {
        rules: [
          {required: true, message: 'Auth0 Client Id required'},
        ],
      },
    },

    {
      id: 'qlikTenantUri',
      type: 'string',
      name: 'Qlik Tenant URI',
      options: {
        rules: [
          {required: true, message: 'Qlik Tenant URI required'},
        ],
      },
    },
    {
      id: 'qlikClientId',
      type: 'string',
      name: 'Qlik Client Id',
      options: {
        rules: [
          {required: true, message: 'Qlik Client Id required'},
        ],
      },
    },
  ];

  const columns: any[] = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
    },
    {
      key: 'createdAt',
      title: 'Created Date',
      dataIndex: 'createdAt',
      render: (_: any, {createdAt}: any) => dayjs(createdAt).format('MMMM D, YYYY h:mm A'),
    },
    {
      key: 'action',
      title: 'Actions',
      dataIndex: 'action',
      render: (_: any, record: any) => (
        <div>
          <Tooltip title="Edit">
            <Button shape="circle" type="link"
                    onClick={() => {
                      connection.get(`clients/apps/sheets/${record.id}`)
                        .then((res) => {
                          record.apps = res.data;
                          delete record.createdAt;
                          delete record.updatedAt;
                          form.setFieldsValue(record)
                          setClientId(record.id);
                          setImage(record?.image)
                          setEdit(true);
                          setVisible(true);
                        })
                        .catch(err => console.log(err))
                    }}><EditOutlined/></Button>
          </Tooltip>
          <Tooltip title={record.status === 'Active' ? 'Deactivate' : 'Activate'}>
            <Popconfirm title={`Confirm Client ${record.status === 'Active' ? 'Deactivation' : 'Activation'}`}
                        onConfirm={
                          () => {
                            connection.patch(`clients/deactivate/${record.id}`).then((res) => {
                              if (res.status === 200) {
                                events.emit('CLIENT', {action: 'UPDATE', data: res.data});
                                events.emit('CLIENT UPDATE');
                                updateFilter({...filter, status: filter.status})
                                message.success(`Client ${res.data.status === 'Active' ? 'Activated' : 'Deactivated'}!`).then();
                              } else {
                                message.error(`Failed!`).then();
                              }
                            })
                          }
                        }>
              <Button shape="circle" type="link">{record.status === 'Active' ? <CloseSquareOutlined/> :
                <CheckSquareOutlined/>}</Button>
            </Popconfirm>
          </Tooltip>
        </div>
      )
    }
  ]

  const uploadButton = (
    <button style={{border: 0, background: 'none'}} type="button">
      {loading ? <LoadingOutlined/> : <PlusOutlined/>}
      <div style={{marginTop: 8}}>Upload</div>
    </button>
  );

  return (
    <div>
      <AdminTitle>
        <div className="text-xl font-bold">Clients</div>
      </AdminTitle>
      <div className="flex flex-row items-center justify-between">
        <Button className="float-left my-5" type='primary' onClick={() =>
          setVisible(true)
        }>Add New Client</Button>
        <div className="flex flex-row items-center gap-10">
          <Select style={{width: 400}} defaultValue={'Active'} options={[{value: 'Active'}, {value: 'Inactive'}]}
                  onChange={onStatusChange}/>
          <Input.Search placeholder="Search" onSearch={onSearch} allowClear/>
        </div>
      </div>
      <Table columns={columns} {...tableProps} />
      {/*<DataEditModal ref={clientCreateModal} type="CLIENT"/>*/}
      <Modal
        width={900}
        confirmLoading={loadingModal}
        title={edit ? 'Edit Client' : 'Create Client'}
        open={visible}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
          setImage(undefined);
          setClientId(undefined);
          setEdit(false);
        }}
        okText="Save"
        onOk={form.submit}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={edit ? onEdit : onCreate}
        >
          <Form.Items schema={schema}/>
          <Form.Item label='Profile Picture' name='profile'>
            <Upload
              name={'avatar'}
              action={''}
              beforeUpload={uploadReq}
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
            >
              {image ? <img src={image} alt="avatar" style={{width: '100%'}}/> : uploadButton}
            </Upload>
          </Form.Item>
          <span className={"font-medium mb-2"}>Client Configurations</span>
          <Form.Items schema={configSchema}/>
          <Form.List name="apps">
            {(fields, {add, remove}) => (
              <div style={{display: 'flex', rowGap: 16, flexDirection: 'column'}}>
                {fields.map((field) => (
                  <Card
                    size="small"
                    title={`App ${field.name + 1}`}
                    key={field.key}
                    extra={
                      <CloseOutlined
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    }
                  >
                    <Form.Item label="App" name={[field.name, 'appId']}>
                      <Select placeholder={'Select App'} options={apps}
                        // onChange={(value) => {
                        //   console.log(value)
                        //   if (value) {
                        //     setSelectedItems((prevState) => [...prevState, value]);
                        //   } else {
                        //     setSelectedItems((prevState) => prevState.pop() as any);
                        //   }
                        // }}
                              allowClear={true}/>
                    </Form.Item>

                    {/* Nest Form.List */}
                    <Form.Item label="Sheets">
                      <Form.List name={[field.name, 'sheets']}>
                        {(subFields, subOpt) => (
                          <div style={{display: 'flex', flexDirection: 'column', rowGap: 16}}>
                            {subFields.map((subField) => (
                              <Space key={subField.key}>
                                <Form.Item noStyle name={[subField.name, 'sheetName']}>
                                  <Input placeholder="Sheet Name"/>
                                </Form.Item>
                                <Form.Item noStyle name={[subField.name, 'sheetId']}>
                                  <Input style={{width: 520}} placeholder="Sheet Id"/>
                                </Form.Item>
                                <CloseOutlined
                                  onClick={() => {
                                    subOpt.remove(subField.name);
                                  }}
                                />
                              </Space>
                            ))}
                            <Button type="dashed" onClick={() => subOpt.add()} block>Add Sheet</Button>
                          </div>
                        )}
                      </Form.List>
                    </Form.Item>
                  </Card>
                ))}

                <Button onClick={() => add()} block>
                  Add App
                </Button>
              </div>
            )}
          </Form.List>
        </Form>
      </Modal>
    </div>
  )
};

export default Clients;
