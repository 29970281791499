const config = {
    // NODE_ENV: process.env.NODE_ENV || 'dev',
    // HOST: process.env.HOST || 'localhost',
    // PORT: process.env.PORT || 3000,
    scope: process.env.SCOPE || 'openid profile email offline_access do:anything',
    audience: process.env.AUDIENCE|| 'https://haloanalytics.au.auth0.com/api/v2/',
    qlikClientId: process.env.REACT_APP_QLIK_CLIENT_ID || 'eb5bd9f2b0d0ed28d36cd871e2f53107',
    clientId: process.env.REACT_APP_CLIENT_ID || 'goiP7hzAjrOfUH8jLPR8KhEmlaVUk3JJ',
    clientSecret: process.env.REACT_APP_CLIENT_SECRET || 'U72y9axkKHOTlEgGKdMmu5j763n2Vv3Vvdb1iI0yuv6VMVcRjERQjWSe2MhnaSXE',
    idpUri: process.env.REACT_APP_IDP_URI || 'haloanalytics.au.auth0.com',
    sessionSecret: process.env.REACT_APP_SESSION_SECRET || 'AmR0$&J8zwGrboJh',
    tenantUri: process.env.REACT_APP_TENANT_URI || 'https://haloanalyticshq.ap.qlikcloud.com/',
    redirectPage: process.env.REACT_APP_REDIRECT_PAGE || 'http://localhost:3000/callback',
    qlikAuthRedirectPage: process.env.REACT_APP_QLIK_AUTH_REDIRECT_PAGE || 'https://thankful-dune-028e1961e.5.azurestaticapps.net/oauth-callback.html',
    basUrl: process.env.REACT_APP_BASE_URL || 'https://halo-backend-app.azurewebsites.net/api',
    cookieDomain: process.env.REACT_APP_COOKIE_DOMAIN || 'localhost'
}

export default config;
