import React, {useEffect, useState} from "react";
import {Select, TreeDataNode, TreeProps} from "antd";
import {Button, Input, Modal, Tree} from "antd";
import {useConnection} from '@sezenta/connection';
import {useAuth0} from "@auth0/auth0-react";
import {PlusCircleOutlined} from "@ant-design/icons";
import TreeDrawer from "./TreeDrawer";
import {useClientContext} from "../../context/ClientContext";

const TreeView: React.FC = () => {
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [gData, setGData] = useState<TreeDataNode[]>([]);
  const connection = useConnection();
  const {user} = useAuth0();
  const {client} = useClientContext();
  const [drawer, setDrawer] = useState<boolean>(false);
  const [appsOptions, setAppsOptions] = useState<any[]>([]);
  const [appsWithSheets, setAppsWithSheets] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<TreeDataNode | null>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [newItemName, setNewItemName] = useState<string>("");

  useEffect(() => {
    if (!user) {
      return;
    }

    const fetchData = async () => {
      try {
        const response = await connection.get(
          `menus/${(user?.sub ?? "").replace("auth0|", "")}/menu`
        );
        const items = response.data.items;
        console.log("items", items);

        const data = transformData(items);
        console.log("data is", data);
        setGData(data);
        setExpandedKeys(data.map((item) => item.key));
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };

    fetchData().then();
  }, [user, connection, client.id]);

  useEffect(() => {
    setAppsOptions([]);
    const getAppsAndSheets = async () => {
      const data = await connection.get(`clients/apps/sheets/${client.id}`);
      setAppsWithSheets(data.data);
      data.data.map((value: any) => {
        setAppsOptions((prev) => {
          return prev.concat([{value: value.appId, label: value.sheetName}])
        })
      })
    }
    if(client.id != ''){
      getAppsAndSheets().then();
    }
  }, [client.id]);

  const transformData = (items: any[]): TreeDataNode[] => {
    return items.map((item) => ({
      title: item.name,
      key: item.id,
      children: item.items ? transformData(item.items) : [],
    }));
  };

  const saveMenuToBackend = async (data: TreeDataNode[]) => {
    console.log("saved Data", data, appsOptions);
    // try {
    //   await connection.post(
    //     `menus/${(user?.sub ?? "").replace("auth0|", "")}/menu`,
    //     { items: data }
    //   );
    //   message.success("Menu saved successfully");
    // } catch (error) {
    //   message.error("Failed to save menu");
    //   console.error("Error saving menu data:", error);
    // }
  };

  const onDragEnter: TreeProps["onDragEnter"] = (info) => {
    console.log("info", info);
  };

  const onDrop: TreeProps["onDrop"] = (info) => {
    console.log(info);
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (
      data: TreeDataNode[],
      key: React.Key,
      callback: (node: TreeDataNode, i: number, data: TreeDataNode[]) => void
    ) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children!, key, callback);
        }
      }
    };

    const data = [...gData];
    let dragObj: TreeDataNode;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        item.children.unshift(dragObj);
      });
    } else {
      let ar: TreeDataNode[] = [];
      let i: number;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i!, 0, dragObj!);
      } else {
        ar.splice(i! + 1, 0, dragObj!);
      }
    }

    // Set the updated tree data
    setGData(data);

    // Save the updated tree data to the backend
    saveMenuToBackend(data);

    // Find and log the updated parent and children of the dragged item
    const findNode = (
      data: TreeDataNode[],
      key: React.Key
    ): TreeDataNode | null => {
      for (const item of data) {
        if (item.key === key) {
          return item;
        }
        if (item.children) {
          const result = findNode(item.children, key);
          if (result) {
            return result;
          }
        }
      }
      return null;
    };

    const findParent = (
      data: TreeDataNode[],
      key: React.Key
    ): TreeDataNode | null => {
      for (const item of data) {
        if (item.children && item.children.some((child) => child.key === key)) {
          return item;
        }
        if (item.children) {
          const result = findParent(item.children, key);
          if (result) {
            return result;
          }
        }
      }
      return null;
    };

    const newParent = findParent(data, dragKey);
    const newChildren = findNode(data, dragKey)?.children || [];

    console.log("New parent of dragged item:", newParent);
    console.log("New children of dragged item:", newChildren);
  };

  const onSelect: TreeProps["onSelect"] = (selectedKeys, info) => {
    const selectedNode = info.node;
    setSelectedItem(selectedNode);
    setDrawer(true);
  };

  const addMenu = () => {
    setModalVisible(true);
  };

  const handleOk = () => {
    const newKey = `new_${Date.now()}`; // Generate a unique key for the new item
    const newItem = {
      title: newItemName,
      key: newKey,
      children: [],
    };

    const updatedData = [...gData, newItem];
    setGData(updatedData);
    setExpandedKeys([...expandedKeys, newKey]); // Ensure the new item is expanded
    setModalVisible(false);
    setNewItemName(""); // Reset the input field

    // Save the updated menu to the backend
    saveMenuToBackend(updatedData);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setNewItemName(""); // Reset the input field
  };

  return (
    <div>
      <div className="flex flex-row justify-center gap-10">
        <div>
          <Tree
            className="draggable-tree p-24 text-xl"
            defaultExpandedKeys={expandedKeys}
            draggable
            blockNode
            onDragEnter={onDragEnter}
            onDrop={onDrop}
            onSelect={onSelect}
            treeData={gData}
          />
        </div>
      </div>
      <Button type="primary" icon={<PlusCircleOutlined/>} onClick={addMenu}>
        Add Menu
      </Button>
      {drawer && selectedItem && (
        <TreeDrawer
          open={drawer}
          onClose={() => setDrawer(false)}
          item={selectedItem}
        />
      )}
      <Modal
        title="Add New Menu Item"
        open={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {/*<Input*/}
        {/*  placeholder="Enter menu item name"*/}
        {/*  value={newItemName}*/}
        {/*  onChange={(e) => setNewItemName(e.target.value)}*/}
        {/*/>*/}
        <Select placeholder={'Select an App'} options={appsOptions} className={"w-full"} />
      </Modal>
    </div>
  );
};

export default TreeView;
