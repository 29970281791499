import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {EventContextProvider} from "./common/EventContext";
import {FormConfigProvider} from "./common/FormConfigProvider";
import {BrowserRouter} from "react-router-dom";
import {HelmetProvider} from "react-helmet-async";
import config from "./common/Config";
import {Auth0Provider} from "@auth0/auth0-react";
import {ConnectionProvider} from '@sezenta/connection';
import {ClientContextProvider} from "./context/ClientContext";
import ThemeProvider from './context/ThemeProvider';
import {EnvironmentContextProvider} from "./context/EnvironmentContext";

const helmetContext = {};
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <EventContextProvider>
      <ConnectionProvider
        baseUrl={config.basUrl}
        cookieDomain={config.cookieDomain}
      >
        <EnvironmentContextProvider>
          {/*<Auth0Provider*/}
          {/*  domain={config.idpUri}*/}
          {/*  clientId={config.clientId}*/}
          {/*  authorizationParams={{redirect_uri: config.redirectPage, scope: config.scope, audience: config.audience}}*/}
          {/*  cookieDomain={config.cookieDomain}*/}
          {/*  useRefreshTokens={true}*/}
          {/*>*/}
            <ClientContextProvider>
              <FormConfigProvider>
                <BrowserRouter>
                  <HelmetProvider context={helmetContext}>
                    <ThemeProvider>
                      <App/>
                    </ThemeProvider>
                  </HelmetProvider>
                </BrowserRouter>
              </FormConfigProvider>
            </ClientContextProvider>
          {/*</Auth0Provider>*/}
        </EnvironmentContextProvider>
      </ConnectionProvider>
    </EventContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
