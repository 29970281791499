export enum MenuItemType {
  Folder = 'Folder',
  Sheet = 'Sheet',
}

export type MenuResponseItemDto = {
  id: number;
  name: string;
  itemType: MenuItemType;
  appId?: string;
  appName?: string;
  sheetId?: string;
  sheetName?: string;
  items?: MenuResponseItemDto[];
}
export type MenuResponseDto = {
  id: number;
  items: MenuResponseItemDto[];
}
