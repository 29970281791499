import { ConfigProvider } from "antd";
import React, { ReactNode } from "react";

function ThemeProvider({ children }: { children: ReactNode }) {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#7F85F5",
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
}

export default ThemeProvider;
