import React, {FC, useEffect, useRef} from 'react';
import {Button, Checkbox, Input, message, Popconfirm, Select, Table, Tooltip} from "antd";
import {FormSchema,} from '@sezenta/antd-schema-form';
import {DataEditModal, DataEditModalInstance} from "../../components/DataEditModal";
import {useDataTable} from "../../components/DataTable";
import {useConnection, useUser} from '@sezenta/connection';
import {useClientContext} from "../../context/ClientContext";
import {AdminTitle} from "../AdminPanelContext";
import {
  CheckOutlined,
  CheckSquareOutlined,
  CloseOutlined,
  CloseSquareOutlined,
  EditOutlined, FileDoneOutlined, FileExcelOutlined,
  LockOutlined, MinusSquareOutlined, PlusSquareOutlined
} from "@ant-design/icons";
import {Role} from "../../common/roles.enum";
import {useEvents} from "../../common/EventContext";

const Users: FC = () => {
  const userCreateModal = useRef<DataEditModalInstance>(null);
  const userEditModal = useRef<DataEditModalInstance>(null);
  const passwordChangeModal = useRef<DataEditModalInstance>(null);
  const {tableProps, setFilter, setSearchEnabled, updateFilter, filter} = useDataTable('users', {
    search: false,
    eventConfig: {name: 'USER', add: {}, updates: ['id']},
    defaultSort: {
      column: 'name'
    }
  });
  const connection = useConnection();
  const {client} = useClientContext();
  const user = useUser();
  const event = useEvents();

  useEffect(() => {
    if (!client || client.id === '') {
      return;
    }
    setFilter({clientId: client.id, status: 'Active'});
    setSearchEnabled(true);
  }, [client, setFilter, setSearchEnabled]);

  const onStatusChange = (status: string) => {
    updateFilter({...filter, status});
  }

  const onSearch = (text: string) => {
    updateFilter({...filter, searchText: text});
  }

  const changeLicense = (isLicense: boolean, userId: string) => {
    connection.post(`qlik/${userId}/${isLicense ? 'unassign' : 'assign'}`, {type: 'analyzer'}).then((res) => {
      if (res.data.status === 201 || res.data.status === 200) {
        event.emit('USER', {action: 'UPDATE', data: res.data.user})
        return message.success(res.data.message);
      }
      return message.error(res.data.message);
    }).catch((e) => console.log(e));
  }

  const schema: FormSchema = [
    {
      id: 'firstName',
      type: 'string',
      name: 'First Name',
      props: {placeholder: 'John'},
      options: {
        rules: [
          {required: true, message: 'First Name is required'},
        ],
      },
    },
    {
      id: 'lastName',
      type: 'string',
      name: 'Last Name',
      props: {placeholder: 'Snow'},
      options: {
        rules: [
          {required: true, message: 'Last Name is required'},
        ],
      },
    },
    {
      id: 'role',
      type: 'select',
      name: 'Role',
      props: {
        options: ['Admin', 'User'],
        placeholder: 'Select a role'
      },
      options: {
        rules: [
          {required: true, message: 'Role is required'},
        ],
      },
    },
  ];

  const createSchema: FormSchema = [
    ...schema,
    {
      id: 'email',
      type: 'email',
      name: 'Email',
      props: {placeholder: 'example@email.com'},
      options: {
        rules: [
          {required: true, message: 'Username is required'},
          {type: 'email', message: 'This should be an email'},
        ],
      },
    },
    {
      id: 'password',
      type: 'password',
      name: 'Password',
      options: {
        rules: [
          {required: true, message: 'Password is required'},
        ],
      },
    },
  ];

  const passwordSchema: FormSchema = [
    {
      id: 'password',
      type: 'password',
      name: 'Password',
      options: {
        rules: [
          {required: true, message: 'Password is required'},
        ],
      },
    },
  ];

  const columns: any[] = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      // sorter: true,
      // sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      key: 'role',
      title: 'Role',
      dataIndex: 'role',
    },
    {
      key: 'client',
      title: 'Client',
      dataIndex: 'client',
      render: (_: any, record: any) => record.client.name
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
    },
    {
      key: 'isLicensed',
      title: 'License',
      dataIndex: 'isLicensed',
      //hidden: user?.role !== Role.Super_Admin,
      align: 'center',
      render: (value: any) => value ? <CheckOutlined/> : <CloseOutlined/>
    },
    {
      key: 'action',
      title: 'Actions',
      dataIndex: 'action',
      render: (_: any, record: any) => (
        <div>
          <Tooltip title="Edit">
            <Button shape="circle" type="link" onClick={() => {
              const data = {
                firstName: record.name.split(' ')[0],
                lastName: record.name.split(' ')[1],
                ...record
              }
              delete data.name;
              delete data.createdAt;
              delete data.updatedAt;
              delete data.email;
              delete data.isLicensed;
              delete data.licenseType;
              delete data.qlikUserId;
              delete data.status;
              delete data.clientId;
              delete data.id;
              userEditModal.current?.edit(data, schema, 'Edit User', `users/${record.id}`, record1 => {
                record1.name = `${record1.firstName} ${record1.lastName}`;
                delete record1.firstName;
                delete record1.lastName;
                return record1;
              });
            }}><EditOutlined/></Button>
          </Tooltip>
          <Tooltip title="Change Password">
            <Button shape="circle" type="link"
                    onClick={() => passwordChangeModal.current?.edit({}, passwordSchema, 'Change Passowrd', `users/password/${record.id}`)}><LockOutlined/></Button>
          </Tooltip>
          {user?.role === Role.Super_Admin && (
            <Tooltip title={record.isLicensed ? "Unassign License" : "Assign License"}>
              <Popconfirm title={`Confirm License ${record.isLicensed ? 'Unassign' : 'Assign'}`}
                          onConfirm={() => changeLicense(record.isLicensed, record.id)}>
                <Button shape="circle"
                        type="link">{record.isLicensed ? <FileExcelOutlined/> : <FileDoneOutlined/>}</Button>
              </Popconfirm>
            </Tooltip>
          )}
          <Tooltip title={record.status === 'Active' ? "Deactivate User" : "Activate User"}>
            <Popconfirm title={`Confirm User ${record.status === 'Active' ? 'Deactivation' : 'Activation'}`}
                        onConfirm={
                          () => {
                            connection.patch(`users/status/${record.id}`).then((res) => {
                              if (res.data.status === 200) {
                                event.emit('USER', {action: 'UPDATE', data: res.data.user});
                                updateFilter({...filter, status: filter.status})
                                message.success(res.data.message).then();
                              } else {
                                message.error(res.data.message).then();
                              }
                            })
                          }
                        }>
              <Button shape="circle" type="link">{record.status === 'Active' ? <CloseSquareOutlined/> :
                <CheckSquareOutlined/>}</Button>
            </Popconfirm>
          </Tooltip>
        </div>
      )
    }
  ]
  return (
    <div>
      <AdminTitle>
        Users
      </AdminTitle>
      <div className="flex flex-row items-center justify-between">
        <Button className="float-left my-5" type='primary' onClick={() =>
          userCreateModal.current?.create(createSchema, `Create User`, `users/create`, record => {
            record.clientId = client.id
            return record;
          })
        }>Add New User</Button>
        <div className="flex flex-row items-center gap-10">
          {user?.role === Role.Super_Admin && (
            <Checkbox onChange={(val) => {
              if (val.target.checked) {
                return updateFilter({...filter, clientId: undefined});
              }
              return updateFilter({...filter, clientId: client.id});
            }} style={{width: '30%'}}>All Users</Checkbox>
          )}
          <Select style={{width: 400}} defaultValue={'Active'} options={[{value: 'Active'}, {value: 'Inactive'}]}
                  onChange={onStatusChange}/>
          <Input.Search placeholder="Search" onSearch={onSearch} allowClear/>
        </div>
      </div>
      <Table rowKey={val => val.id} columns={columns as any} {...tableProps} />
      <DataEditModal ref={userCreateModal} type="USER" width={700}/>
      <DataEditModal ref={userEditModal} type="USER" width={700}/>
      <DataEditModal ref={passwordChangeModal} type="" width={550}/>
    </div>
  )
}

export default Users;
