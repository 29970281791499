'use client';
import React, {
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { message, Modal } from 'antd';
import { FormInstance } from 'antd/es/form';
import { useConnection } from '@sezenta/connection';
import Form, { setValidationErrors } from '@sezenta/antd-schema-form';
import { useEvents } from '../common/EventContext';

export type DataEditModalInstance = {
  edit: (
    item: any,
    schema: any,
    title: string,
    url: string,
    prepare?: (record: any) => any,
    onChange?: (changedValues: any, values: any, form: FormInstance) => void,
  ) => void;
  create: (
    schema: any,
    title: string,
    url: string,
    prepare?: (record: any) => any,
    onChange?: (changedValues: any, values: any, form: FormInstance) => void,
    defaultValue?: any
  ) => void;
};

type Props = {
  type: string;
  width?: string | number;
};

const DataEditModalInternal: ForwardRefRenderFunction<
  DataEditModalInstance,
  Props
> = (props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<any>();
  const [schema, setSchema] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [action, setAction] = useState<string>('EDIT');
  const prepareRef = useRef<(r: any) => any>();
  const onChangeRef =
    useRef<(changedValues: any, values: any, form: FormInstance) => void>();
  const [form] = Form.useForm();
  const connection = useConnection();
  const [valueChangeNotif, setValueChangeNotif] = useState<boolean>(false);
  const events = useEvents();
  useImperativeHandle(
    ref,
    () => ({
      edit: (
        item: any,
        schema: any,
        title: string,
        url: string,
        prepare?: (record: any) => any,
        onChange?: (
          changedValues: any,
          values: any,
          form: FormInstance,
        ) => void,
      ) => {
        setSchema(schema);
        setCurrentItem(item);
        form.resetFields();
        form.setFieldsValue(item);
        setTitle(title);
        setUrl(url);
        setAction('UPDATE');
        prepareRef.current = prepare;
        onChangeRef.current = onChange;
        setVisible(true);
        setValueChangeNotif(!valueChangeNotif);
      },
      create: (
        schema: any,
        title: string,
        url: string,
        prepare?: (record: any) => any,
        onChange?: (
          changedValues: any,
          values: any,
          form: FormInstance,
        ) => void,
        defaultValue?: any
      ) => {
        setSchema(schema);
        setCurrentItem(undefined);
        form.resetFields();
        if (defaultValue) {
          form.setFieldsValue(defaultValue);
        }
        setTitle(title);
        setUrl(url);
        prepareRef.current = prepare;
        onChangeRef.current = onChange;
        setAction('CREATE');
        setVisible(true);
        setValueChangeNotif(!valueChangeNotif);
      },
    }),
    [form, valueChangeNotif],
  );

  const save = useCallback(
    (values: any) => {
      setLoading(true);
      const updates = {
        ...currentItem,
        ...values,
      };
      delete updates.__v;
      (action === 'CREATE' ? connection.post : connection.patch)(
        url,
        prepareRef.current ? prepareRef.current(updates) : updates,
      )
        .then((res) => {
          setVisible(false);
          events.emit(props.type, { action, data: res.data });
        })
        .catch((reason) => {
          console.error(reason);
          if (!setValidationErrors(form, reason)) {
            message.error('Failed to save');
          }
        })
        .finally(() => setLoading(false));
    },
    [
      currentItem,
      action,
      connection.post,
      connection.patch,
      url,
      events,
      props.type,
      form,
    ],
  );

  return (
    <Modal
      width={props.width ?? 900}
      confirmLoading={loading}
      title={title}
      open={visible}
      onCancel={() => setVisible(false)}
      okText="Save"
      onOk={form.submit}
    >
      <Form
        valueChangeNotif={valueChangeNotif}
        layout="vertical"
        onValuesChange={(changedValues, values) =>
          onChangeRef.current?.(changedValues, values, form)
        }
        form={form}
        onFinish={save}
      >
        <Form.Items schema={schema} />
      </Form>
    </Modal>
  );
};

export const useDataEditModal = () => useRef<DataEditModalInstance>(null);

export const DataEditModal = React.forwardRef<DataEditModalInstance, Props>(
  DataEditModalInternal,
);
