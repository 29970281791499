import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';

export const NotFound: FC = () => {
  return (
    <>
      <Helmet>
        <title>404-Not Found</title>
      </Helmet>
      <div className="min-h-screen flex flex-col items-center justify-center">
        <div className="flex flex-col justify-center flex-1  px-6 py-12 lg:px-8  content-center ">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm mt text-center">
            <img
              // src={require('../../../assets/images/logo.png')}
              alt="logo2"
              style={{ width: 64, height: 64 }}
            />
            <p className="text-2xl font-bold leading-9 tracking-tight text-center text-gray-900 mt-20">
              404.
            </p>

            <p className="text-base leading-9 tracking-tight text-center text-gray mt-2">
              The link you are trying to access is either invalid or has
              expired.
            </p>
          </div>
        </div>
        <div className="mt-20 mb-20 flex items-center">

        </div>
      </div>
    </>
  );
};
