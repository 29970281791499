import React, {FC, PropsWithChildren, useContext, useEffect, useState} from 'react';
import {useConnection} from "@sezenta/connection";
import {useClientContext} from "../../context/ClientContext";

export type QlikApp = {
  id: string;
  name: string;
  appId: string;
}

export type QlikSheet = {
  id: string;
  sheetName: string;
  sheetId: string;
  appId: string;
}

export type MenuPageContextData = {
  applications: QlikApp[];
  sheets: QlikSheet[];
};

export const MenuPageContext = React.createContext<MenuPageContextData>({} as any);

export const MenuPageContextProvider: FC<PropsWithChildren> = (props) => {
  const [applications, setApplications] = useState<QlikApp[]>([]);
  const [sheets, setSheets] = useState<QlikSheet[]>([]);
  const connection = useConnection();
  const {client} = useClientContext();

  useEffect(() => {
    if (!client || !client.id) {
      return;
    }
    connection.get(`clients/apps/sheets/${client.id}`).then((res) => {
      setApplications(res.data);
      setSheets(res.data.flatMap((d: { sheets: any; }) => d.sheets))
    })
  }, [client, connection]);

  return <MenuPageContext.Provider value={{applications, sheets}}>{props.children}</MenuPageContext.Provider>;
};

export const useMenuPageContext = () => useContext(MenuPageContext);
