import { PlusOutlined, TeamOutlined } from "@ant-design/icons";
import { Avatar, Button, Divider, List, Menu, Popover } from "antd";
import { PopoverProps } from "antd/lib";
import React, {useMemo, useState} from "react";
import { useClientContext } from "../context/ClientContext";
import styled from "styled-components";

interface ClientSelectorProps extends PopoverProps {
  children: React.ReactNode;
}

interface SelectorCardProps {
  id: string;
  name: string;
  img: string;
}

const SelectorCard = (props: SelectorCardProps) => {
  return (
    <div
      key={props.id}
      style={{
        minWidth: "100px",
      }}
    >
      {props?.img ? <Avatar size={30} shape="circle" src={props.img}/> :  <Avatar style={{backgroundColor: '#7F85F5'}} size={30} shape="circle">{props?.name?.slice(0, 2).toUpperCase()}</Avatar>}
      <span className="text-gray-800 ml-3">{props.name}</span>
    </div>
  );
};

const ClientSelector = (props: ClientSelectorProps) => {
  const { children, ...otherProps } = props;

  const { clients, client, changeClient } = useClientContext();
  const [selectedClient, setSelectedClient] = useState(client);

  const clientsData = useMemo(() => {
    return clients.map((client) => {
      return {
        id: client.id,
        name: client.name,
        img: client?.image,
      };
    });
  }, [clients])

  const renderItem = (client :any) => {
    return (
      <div>
        {selectedClient && (
          <List.Item
            className={`h-[34px] ransition duration-500 rounded-2xl hover:bg-purple-100 cursor-pointer mt-2 ${
              selectedClient?.id === client.id && "bg-purple-200"
            }`}
            onClick={(e) => {
              console.log("Client selected: ", client.id);
              setSelectedClient(client);
              changeClient(client.id);
            }}
          >
            <SelectorCard
              id={client.id}
              name={client.name}
              img={client.img}
            />
          </List.Item>
        )}
      </div>
    )
  }

  return (
    <Popover
      {...otherProps}
      content={
        <div className="flex flex-col min-w-[200px]">
          <div className="flex gap-2 text-gray-900 font-semibold">
            <TeamOutlined className="text-primary text-lg ml-1" />{" "}
            <span>Clients</span>
            <span className="flex w-[3px] h-[20px] bg-primary rounded-sm ml-auto"></span>
          </div>
          <Divider
            style={{
              height: "2px",
              margin: "5px 0 2px 0",
            }}
          />
          <div className="flex flex-col gap-1">
            <List
              dataSource={clientsData}
              renderItem={renderItem}
            />
          </div>
          {/* <Button
            className="flex items-center
          "
            type="dashed"
          >
            <PlusOutlined /> Add a client
          </Button> */}
        </div>
      }
    >
      {children}
    </Popover>
  );
};

export default ClientSelector;
