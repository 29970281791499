import * as React from 'react';
import {Button} from "antd";
import {useAuth0} from "@auth0/auth0-react";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

type Props = {

};
export const Home = (props: Props) => {
    // const {loginWithRedirect} = useAuth0()
  const navigation = useNavigate();
  useEffect(() => {
    navigation('/admin/dashboard')
  }, []);
    return (
        <div>
          {/*<div className="text-center my-10">*/}
          {/*  <span className="text-3xl font-bold">Halo Analytics</span>*/}
          {/*</div>*/}
          {/*<div className="align-middle justify-center flex">*/}
          {/*  <Button type='primary' onClick={() => loginWithRedirect({authorizationParams: {scope: 'openid profile email offline_access do:anything'}})}>Login</Button>*/}
          {/*</div>*/}

        </div>
    );
};
