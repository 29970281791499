import {LockOutlined, LogoutOutlined, MailOutlined} from "@ant-design/icons";
import {useAuth0} from "@auth0/auth0-react";
import {Avatar, Button, Divider, Popover, PopoverProps} from "antd";
import React, {useRef} from "react";
import {DataEditModal, DataEditModalInstance} from "./DataEditModal";
import {FormSchema} from "@sezenta/antd-schema-form";
import {useConnection, useUser} from "@sezenta/connection";

interface ProfilePopoverProps extends PopoverProps {
  children: React.ReactNode;
}

const ProfilePopover = (props: ProfilePopoverProps) => {
  const {children, ...otherProps} = props;
  const passwordChangeModal = useRef<DataEditModalInstance>(null);
  const {logout} = useAuth0();
  const user = useUser();
  const connection = useConnection();

  const passwordSchema: FormSchema = [
    {
      id: 'password',
      type: 'password',
      name: 'Password',
      options: {
        rules: [
          {required: true, message: 'Password is required'},
        ],
      },
    },
  ];

  return (
    <Popover
      {...otherProps}
      content={
        <div className="flex flex-col max-w-[230px]">
          <div className="flex">
            <Avatar
              shape="square"
              alt={user?.name}
              size={35}
              src={
                "https://lh3.googleusercontent.com/-RYqHPrnF5KU/AAAAAAAAAAI/AAAAAAAAAAA/ALKGfknuspjhJ742mFlWP0RoVuiDP2VBag/photo.jpg?sz=46"
              }
            />
            <div className="flex flex-col ml-2 items-start justify-center h-[35px]">
              <p className="font-semibold text-gray-700">{user?.name}</p>
              <div className="flex item-center justify-center items-center">
                <MailOutlined className="text-gray-400 w-[12px] flex rounded mr-1"/>
                <span
                  className="text-xs text-grey-600 mt-[-2px] max-w-[150px] text-ellipsis overflow-hidden opacity-60">
                  {user?.email}
                </span>
              </div>
            </div>
          </div>
          <Divider
            style={{
              height: "1px",
              margin: "10px 0 10px 0",
            }}
          />
          <Button
            type="link"
            className="text-gray-700 flex items-center w-100 p-0"
            onClick={() => passwordChangeModal.current?.edit({}, passwordSchema, 'Change Passowrd', `users/password/${user?.id}`)}
          >
            <LockOutlined/>
            Change Password
          </Button>
          <Button
            type="link"
            className="text-gray-700 flex items-center w-100 p-0"
            onClick={() => {
              connection.logout();
              logout({
                logoutParams: {returnTo: window.location.origin},
              }).then();
            }}
          >
            <LogoutOutlined height={5}></LogoutOutlined>
            Sign out
          </Button>
        </div>
      }
    >
      <DataEditModal ref={passwordChangeModal} type="" width={550}/>
      {children}
    </Popover>
  );
};

export default ProfilePopover;
