import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { Admin } from "./pages/Admin";
import { NotFound } from "./pages/NotFound/NotFound";
import { AdminPanelContextProvider } from "./pages/AdminPanelContext";
import { Empty } from "antd";
import { Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react";
import config from "./common/Config";
import Dashboard from "./pages/Platform Matrics/Dashboard";
import Auth0Callback from "./pages/Auth0Callback";
import { Home } from "./pages/Home";
import Users from "./pages/Users/Users";
import Clients from "./pages/Clients/Clients";
import TreeView from "./pages/TreeView/TreeView";
import {MenusPage} from "./pages/Menus/MenusPage";
import {Unauthorized} from "./pages/NotFound/Unauthorized";

const ProtectedRoute = ({ component, ...args }: any) => (
  <Route
    component={withAuthenticationRequired(component, {
      onRedirecting: () => <div>Loading...</div>,
    })}
    {...args}
  />
);

function App() {
  return (
    <div className="w-full">
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Home />} />
        <Route path="/callback" element={<Auth0Callback />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route
          path="/admin"
          element={
            <AdminPanelContextProvider>
              <Admin />
            </AdminPanelContextProvider>
          }
        >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="users" element={<Users />} />
          <Route path="clients" element={<Clients />} />
          <Route path="tree-view" element={<TreeView />} />
          <Route path="menus" element={<MenusPage />} />
          <Route path="*" element={<Empty />} />
          <Route index element={<Empty />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
