import React, {FC} from 'react';
import {Helmet} from 'react-helmet-async';
import {Button, Result} from "antd";
import {useAuth0} from "@auth0/auth0-react";
import {useConnection} from "@sezenta/connection";

export const Unauthorized: FC = () => {
  const {logout} = useAuth0();
  const connection = useConnection();
  return (
    <>
      <Helmet>
        <title>Unauthorized</title>
      </Helmet>
      <div className="min-h-screen flex flex-col items-center justify-center">
        {/*<div className="flex flex-col justify-center flex-1  px-6 py-12 lg:px-8  content-center ">*/}
        {/*  <div className="sm:mx-auto sm:w-full sm:max-w-sm mt text-center">*/}
        {/*    <img*/}
        {/*      // src={require('../../../assets/images/logo.png')}*/}
        {/*      alt="logo2"*/}
        {/*      style={{ width: 64, height: 64 }}*/}
        {/*    />*/}
        {/*    <p className="text-base leading-9 tracking-tight text-center text-gray mt-2">*/}
        {/*      The page you are trying to access is unauthorized. Please login with authorized account or contact system administrator!*/}
        {/*    </p>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className="mt-20 mb-20 flex items-center">*/}

        {/*</div>*/}
        <Result
          status="403"
          title="403"
          subTitle="The page you are trying to access is unauthorized. Please login with authorized account or contact system administrator!"
          extra={<Button type="primary" onClick={() => {
            connection.logout();
            logout({
              logoutParams: {returnTo: window.location.origin},
            }).then();
          }}>Sign out</Button>}
        />
      </div>
    </>
  );
};
