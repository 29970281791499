import React, {FC, PropsWithChildren, useCallback, useContext, useEffect, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {useConnection} from '@sezenta/connection';
import {useEvents} from "../common/EventContext";

export type ClientDto = {
  id: string;
  name: string;
  image?: string
}

export type ClientContextData = {
  client: ClientDto;
  clients: ClientDto[];
  changeClient: (clientId: string) => void;
};

export const ClientContext = React.createContext<ClientContextData>({} as any);

export const ClientContextProvider: FC<PropsWithChildren> = (props) => {
  const [client, setClient] = useState<ClientDto>({id: '', name: 'Loading...'});
  const [clients, setClients] = useState<ClientDto[]>([]);
  const {user, isAuthenticated} = useAuth0();
  const connection = useConnection();
  const events = useEvents();

  const changeClient = useCallback((clientId: string) => {
    const newClient = clients.find((c) => c.id === clientId);
    console.log('NEW CLIENT', clientId, newClient);
    if (newClient) {
      setClient(newClient);
    }
  }, [clients]);

  useEffect(() => {
    const getClients = () => {
      if (isAuthenticated && user) {
        connection.get(`clients/active-clients`)
          .then(value => {
            console.log('CLIENTS', value.data);
            setClients(value.data);
            setClient(value.data[0]);
          })
          .catch(reason => {
            console.error('Error: ', reason)
          })
      }
      events.on('CLIENT UPDATE', getClients);
      return () => {
        events.off('CLIENT UPDATE', getClients);
      };
    }
    getClients();
  }, [connection, isAuthenticated, events]);
  return <ClientContext.Provider value={{client, clients, changeClient}}>{props.children}</ClientContext.Provider>;
};

export const useClientContext = () => useContext(ClientContext);
