import DayJs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

DayJs.extend(dayOfYear);
DayJs.extend(customParseFormat);
DayJs.extend(relativeTime);
DayJs.extend(updateLocale);
DayJs.extend(timezone);
DayJs.extend(utc);
export const DefaultTimeZone = 'Australia/Sydney';
DayJs.tz.setDefault(DefaultTimeZone);

DayJs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
});

export const dayjs = DayJs.tz;
export class Utils {
  static tryAndDefault<T>(exec: () => T, defaultValue: T) {
    try {
      return exec();
    } catch (e) {
      console.error('Error occurred: ', e);
      return defaultValue;
    }
  }

  static toDayJs(time: string) {
    return DayJs.utc(time).tz(DefaultTimeZone);
  }

  static formatNumber(val: number | string, decimals?: number) {
    if (val === undefined) {
      val = 0;
    }
    const v = typeof val === 'string' ? parseFloat(val) : val;
    return Number(v.toFixed(decimals ?? 0)).toLocaleString('en', {
      minimumFractionDigits: decimals ?? 0,
    });
  }

  static formatPrice(val: number | string, autoDecimal?: boolean) {
    if (val === undefined) {
      val = 0;
    }
    const v = (typeof val === 'string' ? parseFloat(val) : val) / 100;
    const decimals = autoDecimal && v >= 1000 ? 0 : 2;

    return Number(v.toFixed(decimals)).toLocaleString('en', {
      minimumFractionDigits: decimals,
    });
  }

  //GST calculation
  static calculateGST(price: number) {
    if (price === undefined) {
      price = 0;
    }
    const gst = price - price / 1.1;

    return this.formatPrice(gst, true);
  }

  static formatWeight(val: number | string, autoDecimal?: boolean) {
    if (val === undefined) {
      val = 0;
    }
    const v = (typeof val === 'string' ? parseFloat(val) : val) / 1000;
    const decimals = autoDecimal && v >= 1000 ? 0 : 3;

    return Number(v.toFixed(decimals)).toLocaleString('en', {
      minimumFractionDigits: decimals,
    });
  }

  static formatCm(val: number | string) {
    if (val === undefined) {
      val = 0;
    }
    const v = (typeof val === 'string' ? parseFloat(val) : val) / 10;

    return Number(v.toFixed(1)).toLocaleString('en', {
      minimumFractionDigits: 1,
    });
  }

  static getRemainingTime(
    expire: string,
    startDate: string,
    shortFormat?: boolean,
  ): string {
    const short = shortFormat === undefined || shortFormat;
    if (
      startDate &&
      dayjs(
        startDate.toString().substring(0, 10),
        'YYYY-MM-DD',
        DefaultTimeZone,
      )
        .startOf('d')
        .valueOf() > Date.now()
    ) {
      const dt = dayjs(
        startDate.toString().substring(0, 10),
        'YYYY-MM-DD',
        DefaultTimeZone,
      )
        .startOf('d')
        .format('DD MMM');
      return `Coming ${dt}`;
    }

    const diff =
      dayjs(expire.toString().substring(0, 10), 'YYYY-MM-DD', DefaultTimeZone)
        .endOf('d')
        .valueOf() - Date.now();
    if (diff < 0) {
      return 'EXPIRED';
    }
    // console.log(
    //   'DIFF',
    //   diff,
    //   dayjs(expire.toString().substring(0, 10), 'YYYY-MM-DD', DefaultTimeZone)
    //     .endOf('d')
    //     .valueOf(),
    // );
    const d = DayJs.utc(diff);
    const tmstr = d.format(
      short ? 'HH[H]:mm[M]' : 'HH [hours] and mm [minutes]',
    );
    //
    // console.log(
    //   'DIFF',
    //   diff,
    //   startDate,
    //   dayjs(expire.toString().substring(0, 10), 'YYYY-MM-DD', DefaultTimeZone)
    //     .endOf('d')
    //     .valueOf(),
    //   d.dayOfYear(),
    // );

    const days = d.dayOfYear() - 1;
    if (days === 0) {
      return tmstr;
    }
    return short
      ? `${days}D:${d.format('HH[H]')}`
      : `${days} days and ${d.format('H')} hours`
        .replace(' and 0 hours', '')
        .replace(' and 1 hours', 'and 1 hour');
  }

  static productNameForUrl(name: string) {
    if (!name) {
      return name;
    }
    return (name ?? '')
      .replace(/[\W_]+/g, '-')
      .replace(/-+/g, '-')
      .toLowerCase();
  }

  static tryParseInt(value: string | null | undefined, defaultValue?: number) {
    if (value === undefined || value === null) {
      return defaultValue;
    }
    try {
      return parseInt(value, 10);
    } catch (e) {
      return defaultValue;
    }
  }
  static tryParseIntArray(
    value: string[] | null | undefined,
    defaultValue?: number,
  ) {
    if (value === undefined || value === null) {
      return defaultValue;
    }
    return value.map((v) => Utils.tryParseInt(v, defaultValue));
  }

  static getBoxId(id: number) {
    const txt = `000000000000${id}`;
    return `BOX${txt.substring(txt.length - 6)}`;
  }

  static zeroPad(id: number, length: number) {
    const txt = `000000000000${id}`;
    return txt.substring(txt.length - length);
  }

  static formatOrderId(createdAt: Date, orderId: number) {
    return `#${dayjs(
      createdAt.toString().substring(0, 10),
      'YYYY-MM-DD',
      DefaultTimeZone,
    ).format('YYMMDD')}${Utils.zeroPad(orderId, 6)}`;
  }
}

export const tryParseInt = (
  value: string | null | undefined,
  defaultValue?: number,
) => {
  if (value === undefined || value === null) {
    return defaultValue;
  }
  try {
    return parseInt(value, 10);
  } catch (e) {
    return defaultValue;
  }
};

export const tryParseIntArray = (
  value: string[] | null | undefined,
  defaultValue?: number,
) => {
  if (value === undefined || value === null) {
    return defaultValue;
  }
  return value.map((v) => Utils.tryParseInt(v, defaultValue));
};
