import React, {FC, useEffect, useRef} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {Spin} from "antd";
import {useConnection} from "@sezenta/connection";

const Auth0Callback:FC = () => {
    const navigation = useNavigate();
    const connection = useConnection();
    const loc = useLocation();
    const {isAuthenticated, isLoading, user, getAccessTokenSilently, handleRedirectCallback,} = useAuth0();
  const initialized = useRef(false)
    useEffect(() => {
      if (initialized.current) {
        return;
      }
      initialized.current = true;
      const fun = async () => {
        try{
          console.log('Rotiiiiiii');
          const { appState } = await handleRedirectCallback();
          console.log('APPSTATE', appState);
          getAccessTokenSilently().then((res) => {
            console.log('BBBB', res);
            console.log('RRRRRRRR', loc.pathname, loc.search);
            navigation(appState?.returnTo  ?? '/admin/dashboard');
          })
        } catch (e) {
          console.log('ERRRRRRRRRR', e);
        }

      }
      fun().then();
    }, [])
    return(
        <div className="flex items-center justify-center h-screen">
          <Spin spinning={true} />
        </div>
    )
}
export default Auth0Callback;
