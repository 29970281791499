'use client';
import React, { FC, PropsWithChildren } from 'react';
import {
  AntdSchemaFormAdapters,
  AntdSchemaFormValidationAdapters,
} from '@sezenta/antd-schema-form-adapters';
import Form, { MapProps } from '@sezenta/antd-schema-form';
// import { FileUpload } from './FileUpload/FileUpload';
// import { EnumInput } from './EnumInput';
// import { UploadInput } from './FileUpload/UploadInput';
import { InputNumber } from 'antd';
import { DatePicker } from 'antd';

export const FormConfigProvider: FC<PropsWithChildren> = (props) => {
  return (
    <Form.ConfigProvider
      adapters={{
        ...AntdSchemaFormAdapters,
        dateRange: MapProps(DatePicker.RangePicker, {
          style: { width: '100%' },
        }),
        // file: FileUpload,
        // enum: EnumInput,
        // upload: UploadInput,
        price: MapProps(InputNumber, {
          encode: (value) => (value !== undefined ? value * 100 : undefined),
          decode: (value) => (value !== undefined ? value / 100 : undefined),
        }),
      }}
      validators={[...AntdSchemaFormValidationAdapters]}
    >
      {props.children as any}
    </Form.ConfigProvider>
  );
};
