import React, {ForwardRefRenderFunction, useCallback, useImperativeHandle, useMemo, useRef, useState} from 'react';
import {MenuItemType, MenuResponseItemDto} from "./Menu.dto";
import Form, {FormSchema} from "@sezenta/antd-schema-form";
import {useMenuPageContext} from "./MenuPageContext";
import {Modal} from "antd";
import {useConnection} from "@sezenta/connection";
import {useEvents} from "../../common/EventContext";

export type MenuSheetEditorInstance = {
  open: (mode: MenuItemType, parentId: number, value?: MenuResponseItemDto) => void;
};

type Props = {
  menuId: number;
};

const MenuSheetEditor: ForwardRefRenderFunction<MenuSheetEditorInstance, Props> = (props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [mode, setMode] = useState<MenuItemType>(MenuItemType.Sheet);
  const [loading, setLoading] = useState<boolean>(false);
  const [parentId, setParentId] = useState<number>(0);
  const [itemId, setItemId] = useState<number>();
  const [form] = Form.useForm();
  const connection = useConnection();
  const events = useEvents();
  useImperativeHandle(ref, () => ({
    open: (mode, parentId: number, value) => {
      setMode(mode);
      setParentId(parentId);
      setItemId(value?.id);
      form.resetFields();
      form.setFieldsValue(value);
      setVisible(true);
    }
  }), [form]);

  const menuCtx = useMenuPageContext();

  const schema: FormSchema[] = useMemo(() => {
    const name = {
      id: 'name',
      name: 'Name',
      type: 'string',
      props: {placeholder: 'Sheet Name'},
      options: {
        rules: [
          {required: true, message: 'Sheet Name is required'},
        ]
      }
    };
    const sc: FormSchema = [
      name,
      {
        id: 'appId',
        name: 'App',
        type: 'select',
        props: {placeholder: 'Select the App', options: menuCtx.applications.map(a => ({name: a.name, value: a.id}))},
        options: {
          rules: [
            {required: true, message: 'App is required'},
          ]
        }
      },
      {
        id: 'sheetId',
        name: 'Sheet',
        type: 'select',
        props: (values: any) => {
          return {
            placeholder: 'Select the Sheet',
            options: menuCtx.sheets.filter(a => a.appId === values.appId).map(a => ({name: a.sheetName, value: a.id}))
          };
        },
        options: {
          rules: [
            {required: true, message: 'Sheet is required'},
          ]
        }
      }
    ];
    return [[name], sc];
  }, [menuCtx.applications, menuCtx.sheets]);

  const save = useCallback((values: any) => {
    setLoading(true);
    if (itemId) {
      connection.patch(`menus/${props.menuId}/items/${itemId}`, {name: values.name, sheetId: values.sheetId, parentId, itemType: mode})
        .then(res => {
          events.emit('MENU_ITEM', {action: 'UPDATE', data: res.data});
          setVisible(false);
        }).catch((e) => Modal.error({title: 'Error', content: 'Something went wrong!'})).finally(() => setLoading(false));
    } else {
      connection.post(`menus/${props.menuId}/items`, {name: values.name, sheetId: values.sheetId, parentId, itemType: mode})
        .then(res => {
          events.emit('MENU_ITEM', {action: 'ADD', data: res.data});
          setVisible(false);
        }).catch((e) => Modal.error({title: 'Error', content: 'Something went wrong!'})).finally(() => setLoading(false));
    }

  }, [connection, events, itemId, mode, parentId, props.menuId]);

  return (
    <Modal open={visible} title="Menu Item" destroyOnClose={true} onCancel={() => setVisible(false)} onOk={form.submit}
           confirmLoading={loading}>
      <div>
        <Form layout="vertical" form={form} onValuesChange={(changedValues, values) => {
          if (changedValues.appId) {
            form.resetFields(['sheetId']);
          }
        }} onFinish={save}>
          <Form.Items schema={mode === MenuItemType.Folder ? schema[0] : schema[1]}/>
        </Form>
      </div>
    </Modal>
  );
};

export const useMenuSheetEditor = () => useRef<MenuSheetEditorInstance>(null);

export default React.forwardRef<MenuSheetEditorInstance, Props>(MenuSheetEditor);

