import React, {FC, PropsWithChildren, useCallback, useContext, useEffect, useState} from "react";
import {useConnection} from "@sezenta/connection";
import {Auth0Provider} from "@auth0/auth0-react";
import {default as commonConfig} from "../common/Config";
export type EnvironmentContextData = {
  config: any;
}

export const EnvironmentContext = React.createContext<EnvironmentContextData>(
  {} as any
);

export const EnvironmentContextProvider: FC<PropsWithChildren> = (props) => {
  const connection = useConnection();
  const [config, setConfig] = useState<any>();

  const getConfig = useCallback(() => {
    connection.get('clients/configs').then((res) => {
      setConfig(res.data);
    }).catch((e) => console.log(e));
  }, [connection])

  useEffect(() => {
    getConfig();
  }, [getConfig]);

  return (
    <EnvironmentContext.Provider value={{config}}>
      {config && (
        <Auth0Provider
          domain={config.idpUri}
          clientId={config.authClientId}
          authorizationParams={{redirect_uri: config.authRedirectUri, scope: commonConfig.scope, audience: config.audience}}
         // cookieDomain={config.cookieDomain}
          useRefreshTokens={true}
        >
          {props.children}
        </Auth0Provider>
      )}
    </EnvironmentContext.Provider>
  )
}

export const useEnvironmentContext = () => useContext(EnvironmentContext);
