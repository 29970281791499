import React from "react";
import { Drawer } from "antd";
import type { TreeDataNode } from "antd";

interface TreeDrawerProps {
  open: boolean;
  onClose: () => void;
  item: TreeDataNode;
}

const TreeDrawer: React.FC<TreeDrawerProps> = ({ open, onClose, item }) => {
  return (
    <Drawer
      title="Item Details"
      placement="right"
      onClose={onClose}
      open={open}
      width={500}
    >
      <p>{`Title: ${item.title}`}</p>
      <p>{`Key: ${item.key}`}</p>
      {/* Add other item details as needed */}
    </Drawer>
  );
};

export default TreeDrawer;
