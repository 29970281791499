import React, {FC, PropsWithChildren, useContext, useEffect, useRef, useState,} from 'react';

export type AdminPanelContextData = {
  title: React.ReactNode;
  setTitle: (title: React.ReactNode) => void;
  menuName: string;
  setMenuName: (menuName: string) => void;
  bookmarkName: string;
  setBookmarkName: (bookmarkName: string) => void;
  // portalRef:any;
  // setPortalRef: (portalRef:any) => void;
};

export type AdminTitleProps = {
  children: React.ReactNode;
  menuName?: string;
  bookmarkName?: string;
};

export const AdminPanelContext = React.createContext<AdminPanelContextData>(
  {} as any
);

export const AdminPanelContextProvider: FC<PropsWithChildren> = (props) => {
  const [title, setTitle] = useState<React.ReactNode>('');
  const [menuName, setMenuName] = useState<string>('');
  const [bookmarkName, setBookmarkName] = useState<string>('');
  // const [portalRef, setPortalRef] = useState<any>(null);

  return (
    <AdminPanelContext.Provider value={{title, setTitle, menuName, setMenuName, bookmarkName, setBookmarkName}}>
      {props.children}
    </AdminPanelContext.Provider>
  );
};

export const useAdminPanelContext = () => useContext(AdminPanelContext);

export const AdminTitle: FC<AdminTitleProps> = (props) => {
  const ctx = useAdminPanelContext();
  const portalRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    ctx.setTitle(props.children);
    // ctx.setMenuName(props.menuName ?? '');
    // ctx.setMenuName(props.menuName ?? '');
    // ctx.setBookmarkName(props.bookmarkName ?? '');
    // ctx.setPortalRef(portalRef);
  });
  return null;
};
